import { BehaviorSubject } from 'rxjs';

let defaultState = false;

if (sessionStorage.getItem('access-granted')) {
	defaultState = true;
}

const loggedInSubject = new BehaviorSubject(defaultState);

export function setLoggedIn(state) {
	loggedInSubject.next(state);
}

export function getLoggedInState() {
	return loggedInSubject.value;
}

export const loggedInObservable = loggedInSubject.asObservable();
