// Assets
import appLinks from './assets/json/app-links.json';

// Boilerplate
import React, { useEffect, useState } from 'react';

// Mui
// > Boilerplate
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
// > Colors
import { grey } from '@mui/material/colors';

// Third Party Libraries
import EditAccess from './Modules/EditAccess';
import { Route, Routes } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';

// Components
// import Checkout from './Components/Checkout';
const Checkout = React.lazy(() => import('./Components/Checkout'));
// import Landing from './Components/Landing';
const Landing = React.lazy(() => import('./Components/Landing'));
// import Footer from './Components/Footer';
const Footer = React.lazy(() => import('./Components/Footer'));
// import Header from './Components/Header';
const Header = React.lazy(() => import('./Components/Header'));
// import NotFound from './Components/NotFound';
const NotFound = React.lazy(() => import('./Components/NotFound'));

const App = () => {
	const [access, setAccess] = useState(false);
	const baseFontSize = 16; // 16px is the base font size
	// Standard font sizes in pixels for desktop and mobile
	const desktopFontSizes = {
		h1: 60,
		h2: 45,
		h3: 30,
		h4: 25,
		h5: 25,
		h6: 25,
	};
	// Convert pixel font sizes to 'rem' units
	const desktopHeadingSizes = {};
	const isMd = useMediaQuery(useTheme().breakpoints.up('md'));
	const mobileFontSizes = {
		h1: 30,
		h2: 25,
		h3: 20,
		h4: 15,
		h5: 15,
		h6: 15,
	};
	// Convert pixel font sizes to 'rem' units
	const mobileHeadingSizes = {};
	const url = window.location.href;

	useEffect(() => {
		if (!sessionStorage.getItem('access-granted') && url.includes('?')) {
			const checkString = 'access=';
			if (url.includes(checkString)) {
				if (url.split(checkString)[1] === 'true') {
					setAccess(true);
				}
			}
		}
	}, [url]);

	Object.keys(desktopFontSizes).forEach((heading) => {
		desktopHeadingSizes[heading] = `${desktopFontSizes[heading] / baseFontSize}rem`;
	});

	Object.keys(mobileFontSizes).forEach((heading) => {
		mobileHeadingSizes[heading] = `${mobileFontSizes[heading] / baseFontSize}rem`;
	});

	const theme = {
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 900,
				lg: 1200,
				xl: 1536,
			},
		},
		components: {
			MuiButton: {
				defaultProps: {
					color: 'secondary',
					size: 'large',
					variant: 'contained',
				},
				styleOverrides: {
					root: {
						fontFamily: 'Lato, serif',
					},
				},
			},
			MuiListItem: {
				styleOverrides: {
					root: {
						fontFamily: 'Lato, serif',
					},
				},
			},
			MuiPagination: {
				styleOverrides: {
					root: {
						width: 'auto',
						display: 'inline-block',
						margin: '0 auto',
					},
				},
			},
		},
		palette: {
			common: {
				black: '#000000',
				white: grey[50],
			},
			primary: {
				contrastText: grey[50],
				dark: '#a14848',
				light: '#ff9193',
				main: '#cb6c6c',
			},
			secondary: {
				dark: '#445B69',
				light: '#93CFF5',
				main: '#638499',
			},
			warning: {
				dark: '#426332',
				light: '#93CFF5',
				main: '#98B08D',
			},
			text: {
				primary: grey[800],
			},
		},
		spacing: 12,
		typography: {
			// htmlFontSize: isMd ? 12 : 10,
			htmlFontSizes: baseFontSize,
			fontFamily: 'Nanum Gothic, Helvetica Neue, Helvetica, Arial, sans-serif',
			h1: {
				fontFamily: 'Cinzel, Georgia, Times New Roman, Times, serif',
				fontSize: desktopHeadingSizes.h1,
				'@media (max-width: 960px)': {
					fontSize: mobileHeadingSizes.h1,
				},
				fontWeight: 900,
				textAlign: 'center',
				textTransform: 'capitalized',
			},
			h2: {
				fontFamily: 'Cinzel, Georgia, Times New Roman, Times, serif',
				fontSize: desktopHeadingSizes.h2,
				'@media (max-width: 960px)': {
					fontSize: mobileHeadingSizes.h2,
				},
				fontWeight: 900,
				textTransform: 'capitalized',
			},
			h3: {
				fontFamily: 'Cinzel, Georgia, Times New Roman, Times, serif',
				fontSize: desktopHeadingSizes.h3,
				'@media (max-width: 960px)': {
					fontSize: mobileHeadingSizes.h3,
				},
				fontWeight: 900,
				textTransform: 'capitalized',
			},
			h4: {
				fontFamily: 'Cinzel, Georgia, Times New Roman, Times, serif',
				fontSize: desktopHeadingSizes.h4,
				'@media (max-width: 960px)': {
					fontSize: mobileHeadingSizes.h4,
				},
				fontWeight: 900,
				textTransform: 'capitalized',
			},
			h5: {
				fontFamily: 'Cinzel, Georgia, Times New Roman, Times, serif',
				fontSize: desktopHeadingSizes.h5,
				'@media (max-width: 960px)': {
					fontSize: mobileHeadingSizes.h5,
				},
				fontWeight: 900,
				textTransform: 'capitalized',
			},
			h6: {
				fontFamily: 'Cinzel, Georgia, Times New Roman, Times, serif',
				fontSize: desktopHeadingSizes.h6,
				'@media (max-width: 960px)': {
					fontSize: mobileHeadingSizes.h6,
				},
				fontWeight: 900,
				textTransform: 'capitalized',
			},
			body1: {
				fontFamily: 'Nanum Gothic, Helvetica Neue, Helvetica, Arial,sans-serif',
				fontSize: 15,
				lineHeight: 1.7,
			},
			body2: {
				fontFamily: 'Nanum Gothic, Helvetica Neue, Helvetica, Arial,sans-serif',
				fontSize: 15,
				lineHeight: 1.7,
			},
		},
	};

	return (
		<ThemeProvider theme={createTheme(theme)}>
			<CssBaseline />
			<header>
				<Header />
			</header>
			<main>
				<Routes>
					<Route element={<Landing />} path="/" />
					<Route element={<Checkout />} path="/checkout" />
					<Route element={<NotFound />} path="*" />
				</Routes>
			</main>
			<aside>
				<EditAccess state={access} />
				{isMd ? (
					<a
						href={appLinks['contact']['contactCell']['link']}
						target="_blank"
						rel="noreferrer"
					>
						<img
							alt=""
							src="/assets/img/freessentials/whatsapp.png"
							style={{
								cursor: 'pointer',
								position: 'fixed',
								right: 0,
								bottom: '22px',
								height: '82px',
								zIndex: 99,
							}}
						/>
					</a>
				) : null}
			</aside>
			<footer>
				<Footer />
			</footer>
		</ThemeProvider>
	);
};

export default App;
