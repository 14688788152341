import React, { useEffect, useState } from 'react';
import {
	Alert,
	AlertTitle,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { sendUser } from '../Services/api';
import { setLoggedIn } from '../Services/loggedInSubject';

function EditAccess({ state }) {
	const [access, setAccess] = useState(false);
	const [accessSuccess, setAccessSuccess] = useState(true);
	const [errors, setErrors] = useState({});
	const [failureMessage, setFailureMessage] = useState('');
	const [formData, setFormData] = useState({
		username: '',
		password: '',
	});
	const [accessGranted, setAccessGranted] = useState(false);
	const [severity, setSeverity] = useState('error');
	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		if (sessionStorage.getItem('access-granted')) {
			setAccessGranted(true);
		}

		setAccess(state);
	}, [state]);

	const accessRequest = async () => {
		try {
			const response = await sendUser(formData.username, formData.password, 'edit access');
			setAccessSuccess(response.success);
			setFailureMessage(response.error);
			setSeverity('error');
			if (response.success) {
				sessionStorage.setItem('access-granted', 'true');
				setAccessGranted(true);
				// send new logged in state to the rest of the app
				setLoggedIn(true);
				closeDialog();
			}
		} catch (error) {
			console.error(error);
		}
	};

	const closeDialog = () => {
		setAccess(false);
	};

	const handleForm = (event) => {
		const { name, value } = event.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleLogin = () => {
		const formErrors = {};

		// Validate fields
		if (!formData.username) {
			formErrors.username = 'A username is required';
		}

		if (!formData.password) {
			formErrors.password = 'A password is required';
		}

		// Set the validation errors
		setErrors(formErrors);

		if (Object.keys(formErrors).length === 0) {
			accessRequest();
		} else {
			setAccessSuccess(false);
			setSeverity('warning');
			setFailureMessage('Please enter both username and password.');
		}
	};

	return (
		<>
			{accessGranted ? (
				<Alert
					severity="info"
					sx={{
						bottom: 35,
						fontSize: '.8rem',
						lineHeight: '.8rem',
						left: 5,
						pointerEvents: 'none',
						position: 'fixed',
						zIndex: 999,
						'& .MuiAlert-icon': {
							fontSize: '1.1rem',
						},
					}}
				>
					Currently editing.
					<br />
					<sub style={{ fontSize: '.65rem' }}>
						Kindly note some changes may only
						<br />
						take effect when you refresh your page
					</sub>
				</Alert>
			) : null}
			<Dialog open={access} onClose={closeDialog}>
				<DialogTitle>Login To Access Editor UI</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						error={!!errors.username}
						helperText={errors.username}
						margin="dense"
						label="Username"
						type="text"
						name="username"
						value={formData.username}
						onChange={handleForm}
						fullWidth
						required
					/>
					<TextField
						error={!!errors.password}
						helperText={errors.password}
						margin="dense"
						label="Password"
						type={showPassword ? 'text' : 'password'}
						name="password"
						value={formData.password}
						onChange={handleForm}
						fullWidth
						required
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() =>
											setShowPassword((prevShowPassword) => !prevShowPassword)
										}
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					{!accessSuccess && (
						<Alert severity={severity} sx={{ fontSize: '1rem' }}>
							<AlertTitle>Error</AlertTitle>
							{failureMessage}
						</Alert>
					)}
					<Divider />
					<Typography variant="body1">
						<strong>NOTE:</strong> The editing user interface is best used on desktop
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeDialog}>Cancel</Button>
					<Button onClick={handleLogin} variant="contained" color="primary">
						Login
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default EditAccess;
