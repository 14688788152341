// const developmentUrl = 'http://localhost:5000';
const productionUrl = 'https://freessentials.co.za:5000';
export const endpoint = productionUrl;

export const fetchComponent = async (component) => {
	try {
		const response = await fetch(`${endpoint}/${component}`);
		const jsonData = await response.json();
		return jsonData[0]['content'];
	} catch (error) {
		console.error(error);
		throw new Error('Error fetching data');
	}
};

export const sendUser = async (user, password, type) => {
	try {
		const url = `${endpoint}/access`;

		const headers = new Headers();
		headers.append('User', user);
		headers.append('Password', password);
		headers.append('Type', type);

		const requestOptions = {
			method: 'GET',
			headers: headers,
		};

		const response = await fetch(url, requestOptions);
		const jsonData = await response.json();
		return jsonData;
	} catch (error) {
		console.error('Error fetching data:', error);
		throw new Error('Error fetching data');
	}
};

export const updateHome = async (language, component, location, newContent) => {
	try {
		const url = `${endpoint}/${component}/update`;

		const headers = new Headers();
		headers.append('Content-Type', 'application/json');

		const body = JSON.stringify({
			language,
			component,
			location,
			newContent,
		});

		const requestOptions = {
			method: 'PUT',
			headers: headers,
			body: body,
		};

		const response = await fetch(url, requestOptions);
		const jsonData = await response.json();
		return jsonData;
	} catch (error) {
		console.error('Error fetching data:', error);
		throw new Error('Error fetching data');
	}
};

export const deleteProduct = async (product) => {
	try {
		const url = `${endpoint}/freessentials/deleteProduct`;

		const headers = new Headers();
		headers.append('Content-Type', 'application/json');

		const body = JSON.stringify({
			product,
		});

		console.log(body);

		const requestOptions = {
			method: 'DELETE',
			headers: headers,
			body: body,
		};

		const response = await fetch(url, requestOptions);
		const jsonData = await response.json();
		return jsonData;
	} catch (error) {
		console.error('Error fetching data:', error);
		throw new Error('Error fetching data');
	}
};

export const updateProduct = async (category, product, newContent) => {
	try {
		const url = `${endpoint}/freessentials/updateProduct`;

		const headers = new Headers();
		headers.append('Content-Type', 'application/json');

		const body = JSON.stringify({
			category,
			product,
			newContent,
		});

		console.log(body);

		const requestOptions = {
			method: 'PUT',
			headers: headers,
			body: body,
		};

		const response = await fetch(url, requestOptions);
		const jsonData = await response.json();
		return jsonData;
	} catch (error) {
		console.error('Error fetching data:', error);
		throw new Error('Error fetching data');
	}
};
